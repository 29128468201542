import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyCdYJVQSGh2JGeqRqs6UUSFNe1E-OGk2OQ',
  authDomain: 'codex-computerworld.firebaseapp.com',
  projectId: 'codex-computerworld',
  storageBucket: 'codex-computerworld.appspot.com',
  messagingSenderId: '701125105687',
  appId: '1:701125105687:web:f77e8e7e005b0de710e452',
  measurementId: 'G-QG7LEDMB8Q',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
